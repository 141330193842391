<template>
    <div class="flexPage darkPage">
        <navbar :params="pageInfo" />
        <div class="gift">
            <img src="@/assets/img/gift.png" alt="">
        </div>

        <div class="suspend-boxes">
            <!-- 我的推广(人) -->
            <div class="cell-list">
                <span class="title">{{ $t('assets.index.promotion_people') }}</span>
                <div class="justify-between">
                    <span class="tips">{{ $t('assets.index.direct_push') }}</span>
                    <span class="tips">12 {{ $t('assets.index.person') }}</span>
                </div>
            </div>
            <!-- 累计佣金 -->
            <div class="cell-list">
                <span class="title">{{ $t('assets.index.accumulative_commission') }}</span>
                <div class="justify-between">
                    <span class="tips">USDT</span>
                    <span class="tips">12 USDT</span>
                </div>
            </div>
            <!-- 我的推广交易额 -->
            <div class="cell-list">
                <span class="title">{{ $t('assets.index.my_promotional_turnover') }}</span>
                <div class="justify-between">
                    <span class="tips">USDT</span>
                    <span class="tips">12 USDT</span>
                </div>
            </div>
            <!-- 上周我的推广交易额 -->
            <div class="cell-list">
                <span class="title">{{ $t('assets.index.my_promotional_turnover_last_week') }}</span>
                <div class="justify-between">
                    <span class="tips">USDT</span>
                    <span class="tips">12 USDT</span>
                </div>
            </div>
            <!-- 我的推广 -->
            <div class="mt16">
                <van-button @click="$mts.goto('inviteCode')" round size="large" color="#3861FB">{{
                    $t('assets.personal.my_promotion') }}</van-button>
            </div>

        </div>

        <div class="label-ell mt16">
            <span>{{ $t('assets.index.promotion_people') }}</span>
            <van-icon name="arrow" color="#000000" size="20" />
        </div>
        <!-- 佣金明细 -->
        <div class="label-ell">
            <span>{{ $t('assets.index.commission_detail') }}</span>
            <van-icon name="arrow" color="#000000" size="20" />
        </div>
    </div>
</template>

<script>
import navbar from '@/views/modules/navbar.vue'

export default {
    data() {
        return {
            pageInfo: {
                title: '',
                border: false,
                customTitle: this.$t('assets.personal.my_promotion'),
                navbarStyle: 'normal',
                backname: "my",
            },
        }
    },
    components: { navbar },
}
</script>

<style>
.justify-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mt16 {
    margin-top: 4.0712vw;
}

.darkPage {
    background: #F8FAFD;
}

.gift {
    margin-top: 13.2316vw;
    text-align: right;
}

.gift img {
    z-index: 1;
    width: 54.4529vw;
    height: 47.8372vw;
}

.suspend-boxes {
    z-index: 9;
    margin: 0 3.8168vw;
    margin-top: -7.6336vw;
    padding: 0 5.0891vw 4.0712vw 5.0891vw;
    box-sizing: border-box;
    border-radius: 2.5445vw;
    background: #FFFFFF;
}

.suspend-boxes .cell-list {
    padding-top: 6.1069vw;
    padding-bottom: 2.0356vw;
    box-sizing: border-box;
    border-bottom: 1px solid #EFF0F2;
}

.suspend-boxes .cell-list .title {
    font-family: SF Pro;
    font-size: 4.0712vw;
    font-weight: 510;
    color: #0D1421;
}

.suspend-boxes .cell-list .tips {
    font-family: SF Pro;
    font-size: 3.5623vw;
    font-weight: 510;
    color: #616E85;
}

.label-ell {
    padding: 0 4.0712vw;
    margin-left: 3.8168vw;
    margin-right: 3.8168vw;
    margin-bottom: 2.0356vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 12.2137vw;
    border-radius: 2.0356vw;
    background: #FFFFFF;
    box-sizing: border-box;
}
</style>